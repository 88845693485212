import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import { staticRoutes } from "./modules/module";
import store from "../store";
import { formatRouter, createAppIdSinglePage } from "./utils/format";
import { getLoginPath,projectTypeList } from "@/config/index.config.js";
import { getUserInfoToCookie } from "@/utils/user";
import { Components } from '@/router/modules/tianji'
Vue.use(VueRouter);

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
['push','replace'].forEach(key=>{
  const originalFun = VueRouter.prototype[key];
  VueRouter.prototype[key] = function(location, onResolve, onReject) {
    if (onResolve || onReject)
      return originalFun.call(this, location, onResolve, onReject);
    return originalFun.call(this, location).catch((err) => err);
  };
})

// router
const appStaticRoutes = staticRoutes.filter((item => !item.parent || item.parent === '' ))  // - 直接插入根路由的路由
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [ ...appStaticRoutes ], // 增加静态路由
  // 浏览器滚动行为
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 }; // 回滚到(0, 0)
  }
});


// beforeEach
router.beforeEach(async (to, from, next) => {
  const Version = process.env.VUE_APP_BUILD_VERSION

  // 统一取消loading，防止残留
  store.commit("setGlobeLoading", false)

  // 页面跳转进度条
  store.dispatch("setPageProgressLoading", true);

  // 正常登录
  const loginPages =  ['/login','/jzlogin','/yslogin','/jdlogin','/prologin','/tylogin','/sjlogin','/aqlogin','/tjlogin','/tqlogin']
  // const isToLogin = /^(\/v3\/|\/)login(?:\/(?=$))?$/i.test(to.path); // 是否是登录页面
  // const isToLogin = loginPages.includes(to.path); // 是否是登录页面
  if(process.env.VUE_APP_ENV !== 'PRIVATE'&&!['localhost','tj-dev.zmeng123.cn'].includes(window.location.hostname)){
    // 如果是互联网版本tjlogin只能在tjian.zmeng123.cn域名下访问
    if(to.path=='/tjlogin'&&!window.location.hostname.includes('tjian.zmeng123.cn')){
      return next('/login')
    }
    // 如果是互联网版本aqlogin只能在aq.zmeng123.cn域名下访问
    if(to.path=='/aqlogin'&&!window.location.hostname.includes('aq.zmeng123.cn')){
      return next('/login')
    }
  }
  const login_type = projectTypeList.findIndex(t=>t.loginPath==to.path)
  const isToLogin = login_type > -1; // 是否是登录页面
  const isToJumpPage = ['/jump_pages','/jz_sso_login'].includes(to.path); // 是否是中转页面
  if(isToLogin){
    localStorage.setItem('login_type',login_type)
  }
  // 获取logo、标题 等信息
  await store.dispatch("getLogoInfo");

  // 去登陆页面的，直接跳过去
  if (isToLogin|| isToJumpPage) { return next() }

  // 是否是使用Appid外嵌iframe
  const isAppId = to.query.appid;

  // 不是isAppId，正常访问模式
  if (!isAppId) {
    // 获取用户信息
    try {
      // 有regetinfo query时会先请求个人信息，否则会优先从cookie中拿
      if (to.query['regetinfo']) { await getUserInfoToCookie() }
    } catch(e) {
      // 正常情况下获取信息不成功是没有登录导致的，则后端会返回302， 401等状态码，在请求拦截时会跳转，这里加定时只是为了防止未跳转的情况。
      setTimeout(() => { location.href = window.$PublicPath }, 1000)
    }
    
    // 检验登录，未登录的跳到登录页
    const isAuthLogin = Cookies.get(window.$tj_config["cookies"]["LOGINKEY"], { path: "/" }) === "ok"; // 是否已经登录
    if (!isAuthLogin) return next(getLoginPath()); // 去登陆页面重新登录

    // 已登录， 如果没有权限就请求权限
    let fristPage = store.getters.fristPage
    let newAppendRouter = false
    if (!store.getters.permissionList.length && !store.getters.v2_permissionList.length) {
      // 开启loading（从login页面登录时不用增加）
      from.path !== getLoginPath() && store.commit("setGlobeLoading", true)

      // 没有权限 - 需要先获取权限列表， 然后动态添加路由
      await store.dispatch("getPermission");
      const v2_per = store.getters.v2_permissionList
      if(v2_per.length==1&&v2_per[0].name=='sms_main'){
        router.addRoute({
          path:v2_per[0].link,
          component:Components[v2_per[0].name]
        })
        fristPage = '/sms_main'
      }else{
        // 格式化路由并addRoute
        const { layout, topLayout } = formatRouter(Version === 'v3' ? store.getters.permissionList : store.getters.v2_permissionList)
        // 添加layout路由
        router.addRoute(layout)
        // 如果有top路由，添加_top的动态路由
        if (topLayout && topLayout.children && topLayout.children.length)  { router.addRoute(topLayout) }
        // 添加静态路由 (如果没有父级那子集也就不添加了)
        staticRoutes.forEach(itme => {
          if (itme.parent) {
            let parentRoute = router.getRoutes().find(item => item.name === itme.parent)
            if (!parentRoute) return
            router.addRoute(itme.parent, itme)
          }
        })
        // 设置根目录跳转首页
        if( Version === 'v3'){
          fristPage = '/index'
        }else{
          const {isTmHost} = Vue.prototype.$config
          fristPage = isTmHost?"/tm/index":`/${layout.children[0].path}`
        }
      }

      // 最后添加404页面兼容
      router.addRoute({ path: "*/*", redirect: "/404" }); // 添加404路由表
      
      store.commit('setFristPage', fristPage)
      newAppendRouter = true

      // 清除loading
      store.commit("setGlobeLoading", false)
    }

    // 跳转页面
    return next(
      newAppendRouter
      ? (to.path === "/" ? fristPage : to.fullPath)
      : undefined
    )
  }

  // 使用Appid模式
  else if (!router.getRoutes().filter((item) => item.regex.test(to.path)).length >= 1) {
    try {
      // 开启loading（从login页面登录时不用增加）
      // from.path !== '/login' && store.commit("setGlobeLoading", true)
      // 请求校验auth token
      let isAuth = await store.dispatch("appidCheckAuth", {
        appid: to.query.appid,
        nonce: to.query.nonce,
        sign: to.query.sign,
        ts: to.query.ts,
      });
      if (!isAuth) throw new Error();
    } catch (error) {
      return (window.location.href = window.$PublicPath);
    }
    let dynamicRoutes = createAppIdSinglePage(to.path); // 获得单页面route view
    if (!dynamicRoutes.children[0].component) return next(getLoginPath); // 没找到路由的，直接跳回登录
    router.addRoute(dynamicRoutes); // 动态添加此路由
    router.addRoute({ path: "*/*", redirect: "/404" }); // 添加404路由表
    // 清除loading
    // store.commit("setGlobeLoading", false)
    // 跳转页面
    return next(to.fullPath);
  }
  
  // 其他情况 -> return
  next()
})



// 跳转后
router.afterEach(() => {
  store.dispatch("setPageProgressLoading", false)
});

export default router;
